<template>
  <div>
    <div class="hidden-sm-and-down">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
            alt="auto-contraste"
            v-bind="attrs"
            v-on="on"
            class="btn-menu"
          >
            <v-icon color="black50">mdi-circle-half-full</v-icon>
          </v-btn>
        </template>
        <p>Ajuste de cor</p>
      </v-tooltip>
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="aumentarFont()"
            alt="aumentar fonte"
            v-bind="attrs"
            v-on="on"
            class="btn-menu"
          >
            <v-icon color="black50">mdi-format-font-size-increase</v-icon>
          </v-btn>
        </template>
        <p>Aumentar tamanho da fonte</p>
      </v-tooltip>
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="diminuirFont()"
            alt="diminuir fonte"
            v-bind="attrs"
            v-on="on"
            class="btn-menu"
          >
            <v-icon color="black50">mdi-format-font-size-decrease</v-icon>
          </v-btn>
        </template>
        <p>Diminuir tamanho da fonte</p>
      </v-tooltip>
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="imprimirPlano()"
            alt="Imprimir meu Plano Curricular"
            v-bind="attrs"
            v-on="on"
            class="btn-menu"
          >
            <v-icon color="black50">mdi-printer</v-icon>
          </v-btn>
        </template>
        <p>Imprimir meu Plano Curricular</p>
      </v-tooltip>
      <v-dialog v-model="dialog" width="600px">
        <template v-slot:activator="{ on: dialoga, attrs: dig }">
          <v-tooltip bottom color="black">
            <template #activator="{ on: tooltip ,attrs: tool}">
              <v-btn
                icon
                alt="Voltar para Link de Acompanhamento"
                v-on="{ ...tooltip, ...dialoga }"
                v-bind="{ ...tool, ...dig }"
                class="btn-menu"
              >
                <v-icon color="black50">mdi-logout</v-icon>
              </v-btn>
            </template>
            <p>Voltar para Link de Acompanhamento</p>
          </v-tooltip>
        </template>
        <ModalSair @fechaModal="fechaModal" :mobile="false" />
      </v-dialog>
    </div>
    <!-- menu grande -->

    <v-menu bottom :offset-y="true" class="hidden-md-and-up">
      <template v-slot:activator="{ on, attrs }">
        <button
          class="btn-a btn-a-icon hidden-md-and-up"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="icon-mobile">mdi-dots-horizontal</v-icon>
        </button>
      </template>
      <v-list dense min-width="150px">
        <v-list-item class="menu-selecionado">
          <v-list-item-title
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
          >
            <p class="item-menu-mobile">Alto contraste</p>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="menu-selecionado">
          <v-list-item-title @click="aumentarFont()">
            <p class="item-menu-mobile">Aumentar fonte</p>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="menu-selecionado">
          <v-list-item-title @click="diminuirFont()">
            <p class="item-menu-mobile">Diminuir fonte</p>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="menu-selecionado">
          <v-dialog v-model="dialogMobile" width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title @click="logout()" v-bind="attrs" v-on="on">
                <p class="error--text">Sair</p>
              </v-list-item-title>
            </template>
            <ModalSair @fechaModal="fechaModalMobile" :mobile="true" />
          </v-dialog>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import ModalSair from "@/components/ModalSair.vue";
export default {
  components: {
    ModalSair
  },
  data() {
    return {
      dialog: false,
      dialogMobile: false
    };
  },
  methods: {
    aumentarFont() {
      this.$store.dispatch("aumentarFont");
    },
    diminuirFont() {
      this.$store.dispatch("diminuirFont");
    },
    imprimirPlano() {
      this.$emit("fechaMenu", false); // Fechar o menu hamburger
      this.$store.dispatch("fontPadrao");
      this.$vuetify.goTo("#app", {
        duration: 1500,
        offset: 20,
        easing: "easeInOutCubic"
      });
      this.fab = true;
      setTimeout(() => {
        window.print();
      }, 1000);
    },
    fechaModal() {
      this.dialog = false;
    },
    fechaModalMobile() {
      this.dialogMobile = false;
    }
  }
};
</script>
