<template>
  <v-card class="text-center" id="modal-sair">
    <v-card-title class="headline primary white--text">
      <v-spacer v-if="!mobile"></v-spacer>
      <h4 class="s-ml-n6 title-break">Tem certeza que você já vai?</h4>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="fechaModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-img :src="illustration" class="s-ma-n5 img-leave"></v-img>
    <v-card-text class="text-leave">
      <p>
        Ao sair da área de <b>Resultado da Análise</b>, você voltará para o
        <b>Link de Acompanhamento</b>. Deseja fazer isso?
      </p>
    </v-card-text>

    <v-card-actions>
      <button class="s-ma-n2 btn-a btn-a-sec">
        <a :href="link">sair do resultado</a>
      </button>
      <button class="s-ma-n2 btn-a btn-a-pri" @click="fechaModal">
        continuar no resultado
      </button>
    </v-card-actions>
  </v-card>
</template>
<script>
import urlLinks from "@/core/util/urlAcompanhamento.js";
export default {
  name: "ModalSair",
  props: {
    mobile: Boolean
  },
  data: () => ({
    illustration: require(`@/assets/svg/Ilustracoes/illustration-leave.svg`),
    link: ""
  }),
  computed: {
    ie() {
      return this.$store.state.util.ie;
    }
  },
  created() {
    this.setLink();
  },
  methods: {
    setLink() {
      this.link = urlLinks[this.ie.toUpperCase()];
      let decode = atob(this.$route.params.cpf);
      decode = decode.split(";");
      const conc = decode[0];
      const cpf = decode[1];
      this.link = this.link.replace("<concurso>", conc);
      this.link = this.link.replace("<cpf>", cpf);
    },
    fechaModal() {
      this.$emit("fechaModal", false);
    }
  }
};
</script>
