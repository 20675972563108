<template>
  <div class="footer-menu d-print-none">
    <v-bottom-navigation :value="fab" class="primary" :grow="true">
      <v-btn @click="goTransition('#resumo')">
        <span class="text-menu-footer">Resumo</span>
        <v-icon>mdi-circle-edit-outline</v-icon>
      </v-btn>
      <v-btn @click="goTransition('#app')">
        <span class="text-menu-footer">Home</span>
        <v-icon>mdi-home-variant-outline</v-icon>
      </v-btn>

      <v-btn @click="goTransition('#plano')">
        <span class="text-menu-footer">Meu plano</span>
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
export default {
  data: () => ({
    fab: true
  }),
  methods: {
    goTransition(id) {
      this.$vuetify.goTo(id, {
        duration: 1500,
        offset: 20,
        easing: "easeInOutCubic"
      });
      this.fab = true;
    }
  }
};
</script>
