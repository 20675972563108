<template>
  <div
    class="v-speed-dial v-speed-dial--right v-speed-dial--bottom v-speed-dial--fixed v-speed-dial--direction-top v-speed-dial--is-active d-print-none"
    id="menu-fixed"
    :class="{
      's-mb-n7': fontSize >= 1.5
    }"
  >
    <v-btn v-model="fab" class="primary btn-x" fab @click="fab = !fab">
      <v-icon v-if="fab">
        mdi-close
      </v-icon>
      <v-icon v-else>
        mdi-plus
      </v-icon>
    </v-btn>

    <div class="v-speed-dial__list" v-if="fab">
      <v-tooltip left color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="secundary"
            v-bind="attrs"
            v-on="on"
            @click="goTransition('#app')"
            class="white btn-fixo"
          >
            <v-icon color="black60">mdi-home-variant-outline</v-icon>
          </v-btn>
        </template>
        <p>Topo da página</p>
      </v-tooltip>
      <v-tooltip left color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="secundary"
            v-bind="attrs"
            v-on="on"
            @click="goTransition('#resumo')"
            class="white btn-fixo"
          >
            <v-icon color="black60">mdi-circle-edit-outline</v-icon>
          </v-btn>
        </template>
        <p>Resumo</p>
      </v-tooltip>
      <v-tooltip left color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="secundary"
            v-bind="attrs"
            v-on="on"
            @click="goTransition('#plano')"
            class="white btn-fixo"
          >
            <v-icon color="black60">mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
        <p>Plano curricular</p>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    fab: true
  }),
  computed: {
    fontSize() {
      return this.$store.state.util.font;
    }
  },
  methods: {
    goTransition(id) {
      this.$vuetify.goTo(id, {
        duration: 1500,
        offset: 20,
        easing: "easeInOutCubic"
      });
      this.fab = true;
    }
  }
};
</script>
