var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-print-none"},[_c('v-navigation-drawer',{class:_vm.aluno.sgl_marca.toUpperCase(),attrs:{"app":"","disable-resize-watcher":true},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-layout',{staticClass:"hidden-sm-and-up",attrs:{"column":"","align-center":""}},[_c('v-flex',{staticClass:"s-ma-n6"},[_c('img',{class:_vm.aluno.sgl_marca.toLowerCase() + '-logo-lateral',attrs:{"src":_vm.logoSvgNegativo,"alt":_vm.aluno.sgl_marca}})])],1),_c('h5',{staticClass:"s-ma-n4 mb-2 white--text"},[_vm._v("RESULTADO DA ANÁLISE")]),_c('v-list',{attrs:{"dense":"","dark":""}},[_c('v-list-item',{staticClass:"ma-0 pa-0",attrs:{"link":""},on:{"click":function($event){return _vm.$vuetify.goTo('#resumo', {
            duration: 1500,
            offset: 40,
            easing: 'easeInOutCubic'
          })}}},[_c('p',{staticClass:"s-ma-n4 mt-2 mb-2"},[_vm._v("Resumo")])]),_c('v-list-item',{staticClass:"ma-0 pa-0",attrs:{"link":""},on:{"click":function($event){return _vm.$vuetify.goTo('#plano', {
            duration: 1500,
            offset: 40,
            easing: 'easeInOutCubic'
          })}}},[_c('p',{staticClass:"s-ma-n4 mt-2 mb-2"},[_vm._v("Plano curricular")])])],1),(_vm.$vuetify.breakpoint.name === 'xs')?_c('v-btn',{staticClass:"btn-menu white--text",attrs:{"icon":"","id":"btn-hide-menu"},on:{"click":function($event){$event.stopPropagation();return _vm.setDrawer.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-app-bar',{attrs:{"app":"","color":"secondary","height":"60"}},[_c('v-app-bar-nav-icon',{staticClass:"btn-menu",on:{"click":function($event){$event.stopPropagation();return _vm.setDrawer.apply(null, arguments)}}},[(!_vm.drawer)?_c('v-icon',[_vm._v("mdi-menu")]):_c('v-icon',[_vm._v("mdi-menu-open")])],1),(this.$vuetify.breakpoint.name !== 'xs')?_c('v-spacer'):_vm._e(),_c('v-toolbar-title',{staticClass:"logo-menu"},[(!_vm.$vuetify.theme.dark)?_c('img',{class:_vm.aluno.sgl_marca.toLowerCase() + '-logo',attrs:{"src":_vm.logoSvg,"alt":_vm.aluno.sgl_marca}}):_c('img',{class:_vm.aluno.sgl_marca.toLowerCase() + '-logo',attrs:{"src":_vm.logoSvgNegativo,"alt":_vm.aluno.sgl_marca}})]),_c('v-spacer'),_c('ItensMenu',{on:{"fechaMenu":function($event){_vm.drawer = $event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }