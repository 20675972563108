const linksCalendario = {
  UNIBH: "https://www.unibh.br/o-unibh/calendario-academico-unibh/",
  USJT: "https://www.usjt.br/calendario/",
  UNA: "https://www.una.br/calendarios-academicos/",
  SOCIESC: "https://www.unisociesc.com.br/a-unisociesc/calendario-academico/",
  AGES: "https://ages.edu.br/calendario-academico/",
  FASEH: "https://faseh.edu.br/calendario/",
  UNISUL: "http://www.unisul.br/calendario-academico/",
  UNICURITIBA:
    "https://www.unicuritiba.edu.br/Portal-do-Aluno/calendario-academico.html",
  UNIFG: "https://unifg.edu.br/institucional/calendario/"
};
export default linksCalendario;
