<template>
  <v-card class="d-print-none s-mb-n7">
    <img :src="feedback" alt="Mulher mexendo no notebook" class="feedback" />
    <v-card-title class="titulo-card pa-0 s-ma-n3">
      <v-icon left color="primary">mdi-file-document-outline</v-icon>
      <h3>Plano Curricular</h3>
    </v-card-title>
    <v-card-actions class="s-pa-n3 s-ma-n3">
      <p>
        Para uma melhor visualização de seu plano, sugerimos que faça esse
        acesso em um computador, ou em uma tela maior.
      </p>
      <p>
        Mas se não der ou preferir acessar por aqui, tudo bem! É só fazer o
        download em PDF para seu celular.
      </p>
      <button
        class="btn-a btn-a-pri primary btn-mobile mt-4 s-mb-n3"
        @click="downloadPdf()"
      >
        <v-icon left color="white">mdi-download</v-icon>
        Baixar meu Plano Curricular
      </button>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "PlanoMobile",
  data() {
    return {
      feedback: require(`@/assets/svg/Ilustracoes/illustration-note.svg`)
    };
  },
  methods: {
    downloadPdf() {
      this.$store.dispatch("fontPadrao");
      this.$vuetify.goTo("#app", {
        duration: 1500,
        offset: 20,
        easing: "easeInOutCubic"
      });
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }
};
</script>
