<template>
  <v-row class="s-pa-n0 ma-0 pl-md-7 pa-0">
    <v-col cols="12" md="6" id="texto-deferimento">
      <h2>{{ primeiroNome(aluno.nom_aluno) }},</h2>
      <div id="texto-padrao" v-if="!erro" v-html="texto"></div>
      <div id="integralizacao" v-if="aluno.ind_e2a2 === 'S'">
        <h3>Abaixo o descritivo da análise completa realizada:</h3>
        <h4>Análise de histórico</h4>
        <p v-html="aluno.obs_deferimento"></p>
        <p>
          Todas as dispensas foram de acordo com nossos padrões institucionais e
          podem ser visualizadas logo abaixo, tanto no
          <b>Resumo de horas aproveitadas em seu currículo</b>, quanto
          posteriormente em seu <b>Plano Curricular.</b>
        </p>
        <h4>Resumo de horas aproveitadas</h4>
        <DadosIntegralizacao />
      </div>
      <div id="texto-finalizacao">
        <p v-if="aluno.ind_e2a2 === 'S'">
          Agora que você já conheceu sua análise, se quiser, você já pode
          continuar com seu processo de entrada com a gente!
        </p>
        <button
          class="s-mb-n3 btn-a btn-a-pri btn-large d-print-none"
          v-if="aluno.ind_e2a2 === 'S'"
        >
          <v-icon class="white--text s-mr-n1 text-md-body-2 "
            >mdi-arrow-right</v-icon
          >
          <a :href="link" class="white--text"
            >Prosseguir com minha matrícula
          </a>
        </button>
        <p>Esperamos por você.</p>
        <p>{{ aluno.instituicao }}</p>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="flex-column hidden-sm-and-down d-flex d-print-none"
    >
      <img
        :src="illustrationPaper"
        class="resumo-analise-imagem hidden-sm-and-down"
      />
      <img
        v-if="aluno.ind_e2a2 === 'S'"
        :src="illustrationTeacher"
        class="resumo-analise-imagem mt-auto hidden-sm-and-down"
      />
    </v-col>
  </v-row>
</template>
<script>
import primeiroNome from "@/core/util/functions";
import linksCalendario from "@/core/util/urlCalendario.js";
import apiAcompanhamento from "@/core/api-access/api-acompanhamento";
import DadosIntegralizacao from "@/components/PlanoCurricular/DadosIntegralizacao.vue";
export default {
  name: "ResumoAnalise",
  components: {
    DadosIntegralizacao
  },
  data: () => ({
    illustrationPaper: require(`@/assets/svg/Ilustracoes/illustration-paper.svg`),
    illustrationTeacher: require(`@/assets/svg/Ilustracoes/illustration-teacher.svg`),
    linkCalendario: "",
    texto: "",
    erro: false
  }),
  computed: {
    aluno() {
      return this.$store.state.aluno.aluno;
    },
    link() {
      return this.$store.state.util.link;
    }
  },
  created() {
    this.setLink();
    this.setTexto();
  },
  methods: {
    primeiroNome: primeiroNome,
    setLink() {
      this.linkCalendario = linksCalendario[this.aluno.sgl_marca];
    },
    setLinkUlife() {
      let linkUlife =
        "https://aluno.<marca>.br/SOL/aluno/index.php/index/seguranca/dev/instituicao/3/acesso/pre/param";
      linkUlife = linkUlife.replace("<marca>", this.aluno.sgl_marca);
      linkUlife = `<a href="${linkUlife}">abrir um protocolo de "ALTERAÇÃO CADASTRAL COI"</a>`;
      return linkUlife;
    },
    renderButton() {
      let button = `<button
          class="s-mb-n3 btn-a btn-a-pri btn-large d-print-none"
        >
         <i aria-hidden="true" class="v-icon notranslate white--text s-mr-n1 text-md-body-2 mdi mdi-arrow-right theme--light"></i>
          <a href="${this.link}" class="white--text"
            >Prosseguir com minha matrícula
          </a>
        </button>`;
      return button;
    },
    async setTexto() {
      let body = { ind_e2a2: this.aluno.ind_e2a2 };
      await apiAcompanhamento
        .buscaTextoDeferimento(body)
        .then(response => {
          if (response.status == 200) {
            //deu certo a busca
            this.texto = response.data.data.texto.texto;
            if (this.aluno.ind_e2a2 == "N") {
              this.texto = this.texto.replace("<link>", this.setLinkUlife());
              this.texto = this.texto.replace(
                "<link-acompanhamento>",
                this.renderButton()
              );
              this.texto = this.texto.replace(
                "<link-calendário>",
                `<a href="${this.linkCalendario}">conferir o Calendário Acadêmico</a>`
              );
              this.texto = this.texto.replace(
                "<observacao>",
                `<p>${this.aluno.obs_deferimento}</p>`
              );
            }
          } else {
            //deu errado a busca
            this.erro = "true";
          }
        })
        .catch(() => {
          this.erro = "true";
        });
    }
  }
};
</script>
