<template>
  <v-tooltip
    :top="direcao == 'right' ? false : direcaoTooltip"
    :bottom="direcao == 'right' ? false : !direcaoTooltip"
    :right="direcao == 'right' ? true : false"
    content-class="tooltip-plano"
    :max-width="tamanho"
  >
    <template v-slot:activator="{ on, attrs }">
      <img :src="url" alt="tooltip" v-bind="attrs" v-on="on" class="question" />
    </template>
    <p>
      {{ texto }}
    </p>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipQuestion",
  props: {
    texto: String,
    cor: { type: String, default: "primary" },
    tamanho: { type: String, default: "385px" },
    direcao: { type: String, default: "top" }
  },
  computed: {
    url() {
      if (this.cor == "branco") {
        return require(`@/assets/svg/icons/question-white.svg`);
      } else {
        return require(`@/assets/svg/icons/question.svg`);
      }
    },
    direcaoTooltip() {
      if (this.direcao == "top") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
