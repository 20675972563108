<template>
  <div>
    <v-simple-table
      id="integraliza-table"
      class="mb-4 hidden-sm-and-down"
      v-if="carregado"
    >
      <template v-slot:default>
        <thead class="primary">
          <tr>
            <th class="text-left white--text th-1">
              Tipo
              <tooltip-question
                texto="Aqui você tem as classificações de horas aproveitadas de acordo com sua análise."
                cor="branco"
                tamanho="195px"
              />
            </th>
            <th class="text-left white--text th-2">
              Carga horária total
            </th>
            <th class="text-left white--text th-3">
              Dispensa
              <tooltip-question
                texto="Aqui temos horas DISPENSADAS totais que você recebeu em cada classificação."
                cor="branco"
                tamanho="195px"
              />
            </th>
            <th class="text-left white--text th-4">
              A cursar
              <tooltip-question
                texto="Aqui temos horas A CURSAR totais que você recebeu em cada classificação."
                cor="branco"
                tamanho="195px"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="tr-linha"
            v-for="integra in integralizacao"
            :key="integra.id"
          >
            <td class="td-plano">
              {{ integra.dsc_nat_disciplina }}
              <tooltip-question
                v-if="integra.dsc_nat_disciplina == 'Extensão'"
                direcao="right"
                texto="Tem por objetivo o enriquecimento curricular e promove a interação entre a Instituição de Ensino e a Sociedade por meio de ações, programas, projetos, prestações de serviço, cursos e oficinas, eventos acadêmicos, esportivos e culturais, publicações e outros produtos acadêmicos, atendendo a demandas da comunidade em prol da sociedade e da região onde está inserida."
              />
              <tooltip-question
                v-if="
                  integra.dsc_nat_disciplina == 'Ativ.Complementar de Graduação'
                "
                direcao="right"
                texto="Práticas acadêmicas de múltiplos formatos, com o objetivo de complementar sua formação, ampliar o seu conhecimento teórico-prático com atividades extraclasse, fomentar a prática de trabalho entre grupos e a interdisciplinaridade, estimulando as atividades de caráter solidário e incentivando a tomada de decisão e seu espírito empreendedor."
              />
            </td>
            <td class="td-plano">{{ integra.previsto }} horas</td>
            <td class="td-plano">
              {{
                integra.previsto - integra.acursar == 0
                  ? "-"
                  : integra.previsto - integra.acursar + " horas"
              }}
            </td>
            <td class="td-plano">{{ integra.acursar }} horas</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="mb-4 hidden-sm-and-down" v-else>
      <v-img :src="tabela"></v-img>
    </div>

    <div class="mb-4 hidden-md-and-up d-print-none" v-if="carregado">
      <v-row
        v-for="integra in integralizacao"
        :key="integra.id"
        id="tabela-Mobile"
        class="s-pa-n3 s-pl-n6"
      >
        <span class="titulo-integraliza"
          >{{ integra.dsc_nat_disciplina }}
          <tooltip-question
            v-if="integra.dsc_nat_disciplina == 'Extensão'"
            tamanho="60vw"
            direcao="top"
            texto="Tem por objetivo o enriquecimento curricular e promove a interação entre a Instituição de Ensino e a Sociedade por meio de ações, programas, projetos, prestações de serviço, cursos e oficinas, eventos acadêmicos, esportivos e culturais, publicações e outros produtos acadêmicos, atendendo a demandas da comunidade em prol da sociedade e da região onde está inserida."
          />
          <tooltip-question
            v-if="
              integra.dsc_nat_disciplina == 'Ativ.Complementar de Graduação'
            "
            tamanho="60vw"
            direcao="top"
            texto="Práticas acadêmicas de múltiplos formatos, com o objetivo de complementar sua formação, ampliar o seu conhecimento teórico-prático com atividades extraclasse, fomentar a prática de trabalho entre grupos e a interdisciplinaridade, estimulando as atividades de caráter solidário e incentivando a tomada de decisão e seu espírito empreendedor."
          />
        </span>
        <table width="90%">
          <tr>
            <td><span>Carga horária total</span></td>
            <td class="font-weight-bold text-end">
              <span>{{ integra.previsto }} horas</span>
            </td>
          </tr>
          <tr>
            <td><span>Dipensada</span></td>
            <td class="font-weight-bold text-end">
              <span>{{
                integra.previsto - integra.acursar == 0
                  ? "-"
                  : integra.previsto - integra.acursar + " horas"
              }}</span>
            </td>
          </tr>
          <tr>
            <td><span>A cursar</span></td>
            <td class="font-weight-bold text-end">
              <span>{{ integra.acursar }} horas</span>
            </td>
          </tr>
        </table>
      </v-row>
    </div>
    <div class="mb-4 hidden-md-and-up" v-else>
      <img :src="tabelaMobile" width="90%" />
    </div>
  </div>
</template>
<script>
import apiAcompanhamento from "@/core/api-access/api-acompanhamento";
import TooltipQuestion from "../shared/TooltipQuestion.vue";

export default {
  components: { TooltipQuestion },
  data() {
    return {
      tabela: require(`@/assets/svg/Loading/tabela.svg`),
      tabelaMobile: require(`@/assets/svg/Loading/tabelaMobile.svg`),
      integralizacao: {},
      carregado: false,
      msgErro: null
    };
  },
  created() {
    this.buscaTabelaIntegralizacao();
  },
  methods: {
    async buscaTabelaIntegralizacao() {
      await apiAcompanhamento
        .buscaDadosIntegralizacao()
        .then(response => {
          if (response.status == 200) {
            //deu certo a busca
            this.integralizacao = response.data.data.integracao;
            // this.$store.dispatch("alterarAluno", this.aluno);
            this.carregado = true;
          } else {
            //deu errado a busca
            this.msgErro = "Dados não encontrados.";
          }
        })
        .catch(() => {
          this.msgErro = "Dados não encontrados.";
        });
    }
  }
};
</script>
