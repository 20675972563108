// let var_redirect_uri = window.location.href;
// if (
//   process.env.NODE_ENV === "staging-aca" ||
//   process.env.NODE_ENV === "staging-fin" ||
//   process.env.NODE_ENV === "support"
// ) {
//   var_redirect_uri = window.location.href;
//   //console.log(var_redirect_uri);
// } else {
//   var_redirect_uri = process.env.VUE_APP_IDENTITY_REDIRECT;
// }

// const oidc = {
//   authority: process.env.VUE_APP_IDENTITY_URL,
//   client_id: process.env.VUE_APP_IDENTITY_CLIENT,
//   redirect_uri: var_redirect_uri,
//   response_type: process.env.VUE_APP_IDENTITY_RESPONSE_TYPE,
//   scope: process.env.VUE_APP_IDENTITY_SCOPE,
//   post_logout_redirect_uri: process.env.VUE_APP_IDENTITY_REDIRECT,
//   loadUserInfo: true
// };

// const settings = {
//   permissao: {
//     key: "PERMISSAO"
//   }
// };

const api = {
  api_ingressa_coi: process.env.VUE_APP_URL_INGRESSAR_COI
};

const configuration = {
  build: {
    //oidc: oidc,
    api: api
    //settings: settings
  },
  support: {
    //oidc: oidc,
    api: api
    //: settings
  },
  staging_aca: {
    //oidc: oidc,
    api: api
    //settings: settings
  },
  staging_fin: {
    //oidc: oidc,
    api: api
    //settings: settings
  },
  dev: {
    //oidc: oidc,
    api: api
    //settings: settings
  }
};

let config = {
  production: configuration.build,
  support: configuration.support,
  "staging-aca": configuration.staging_aca,
  "staging-fin": configuration.staging_fin,
  development: configuration.dev,
  test: configuration.dev
};

const appConfig = config[process.env.NODE_ENV];
export default appConfig;
