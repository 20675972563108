<template>
  <div>
    <v-app-bar
      app
      color="secondary"
      height="60"
      v-if="logoSvg != '' && logoSvgNegativo != ''"
    >
      <v-spacer />
      <v-toolbar-title>
        <img
          :src="logoSvg"
          :alt="ie"
          :class="ie.toLowerCase() + '-logo'"
          v-if="!$vuetify.theme.dark"
        />
        <img
          :src="logoSvgNegativo"
          :alt="ie"
          :class="ie.toLowerCase() + '-logo'"
          v-else
        />
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-container fill-height class="justify-center">
      <v-card
        width="327px"
        class="text-center"
        :style="{ marginTop: $vuetify.breakpoint.height / 3 + 'px' }"
      >
        <v-card-text>
          <img :src="spin" alt="loading" width="50px" />
          <h2 class="s-mb-n2">Tá quase!</h2>
          <p class="s-mb-n2">Estamos carregando seu plano para você.</p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Loading",
  data: () => ({
    spin: require(`@/assets/svg/Loading/spin.svg`),
    logoSvg: "",
    logoSvgNegativo: ""
  }),
  computed: {
    ie() {
      return this.$store.state.util.ie;
    }
  },
  created() {
    this.setLogo();
  },
  methods: {
    setLogo() {
      try {
        this.logoSvg = require(`@/assets/svg/Marcas/${this.ie.toLowerCase()}.svg`);
        this.logoSvgNegativo = require(`@/assets/svg/Marcas/${this.ie.toLowerCase()}-branco.svg`);
      } catch (e) {
        this.logoSvg = "";
        this.logoSvgNegativo = "";
      }
    }
  }
};
</script>
