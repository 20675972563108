<template>
  <v-col cols="12" class="ma-0">
    <v-card class="s-pa-n4">
      <v-card-title class="titulo-card pa-0">
        <v-icon left color="primary">mdi-file-document-outline</v-icon>
        <h3>Plano Curricular</h3>
      </v-card-title>

      <v-card-actions class="pa-0">
        <v-row class="s-ml-n5 s-mt-n3 mb-0" id="plano-legenda">
          <v-col cols="12" md="3" class="ma-0">
            <h5>
              Dispensada
              <v-icon right color="success" size="20" class="mb-1"
                >mdi-check-circle</v-icon
              >
            </h5>
            <p class="s-pr-n6">
              Unidades curriculares (UC’s) as quais você obteve dispensa em seu
              novo curso.
            </p>
          </v-col>
          <v-col cols="12" md="3" class="ma-0">
            <h5>
              A cursar
              <v-icon right color="warning" size="20" class="mb-1"
                >mdi-alert-circle</v-icon
              >
            </h5>
            <p class="s-pr-n6">
              Unidades curriculares (UC’s) as quais você não obteve dispensa e
              precisará cursar.
            </p>
          </v-col>
          <v-col cols="12" md="3" class="ma-0">
            <h5>
              Aprovada
              <v-icon right color="primary" size="20" class="mb-1"
                >mdi-check-circle</v-icon
              >
            </h5>
            <p class="s-pr-n6">
              Unidades Curriculares (UC) que foram cumpridas na própria
              instituição com mesmo conteúdo.
            </p>
          </v-col>
          <v-col cols="12" md="3" class="ma-0">
            <h5>
              Cursando
              <v-icon right color="purple" size="20" class="mb-1"
                >mdi-circle-slice-5</v-icon
              >
            </h5>
            <p class="s-pr-n7">
              Unidades Curriculares (UC) as quais você se matriculou no semestre
              atual e ainda não finalizou.
            </p>
          </v-col>
        </v-row>
        <PlanoTabela v-if="ind_e2a2 == 'S'" />
        <PlanoTabelaGradeAntiga v-else />
      </v-card-actions>
    </v-card>
    <button
      class="s-ma-n4 s-mr-n0 btn-a btn-a-pri btn-large float-right hidden-sm-and-down d-print-none"
    >
      <v-icon class="white--text s-mr-n1 text-md-body-2"
        >mdi-arrow-right</v-icon
      >
      <a :href="link" class="white--text">Prosseguir com minha matrícula </a>
    </button>
  </v-col>
</template>
<script>
import PlanoTabela from "@/components/PlanoCurricular/PlanoTabela.vue";
import PlanoTabelaGradeAntiga from "@/components/PlanoCurricular/PlanoTabelaGradeAntiga.vue";
export default {
  name: "PlanoContainer",
  components: {
    PlanoTabela,
    PlanoTabelaGradeAntiga
  },
  computed: {
    ind_e2a2() {
      return this.$store.state.aluno.aluno.ind_e2a2;
    },
    link() {
      return this.$store.state.util.link;
    }
  }
};
</script>
