<template>
  <div class="d-print-none">
    <!-- sidebar -->
    <v-navigation-drawer
      v-model="drawer"
      :class="aluno.sgl_marca.toUpperCase()"
      app
      :disable-resize-watcher="true"
    >
      <v-layout column align-center class="hidden-sm-and-up">
        <v-flex class="s-ma-n6">
          <img
            :src="logoSvgNegativo"
            :alt="aluno.sgl_marca"
            :class="aluno.sgl_marca.toLowerCase() + '-logo-lateral'"
          />
        </v-flex>
      </v-layout>
      <h5 class="s-ma-n4 mb-2 white--text">RESULTADO DA ANÁLISE</h5>
      <v-list dense dark>
        <v-list-item
          class="ma-0 pa-0"
          link
          @click="
            $vuetify.goTo('#resumo', {
              duration: 1500,
              offset: 40,
              easing: 'easeInOutCubic'
            })
          "
        >
          <p class="s-ma-n4 mt-2 mb-2">Resumo</p>
        </v-list-item>
        <v-list-item
          class="ma-0 pa-0"
          link
          @click="
            $vuetify.goTo('#plano', {
              duration: 1500,
              offset: 40,
              easing: 'easeInOutCubic'
            })
          "
        >
          <p class="s-ma-n4 mt-2 mb-2">Plano curricular</p>
        </v-list-item>
      </v-list>
      <v-btn
        icon
        class="btn-menu white--text"
        id="btn-hide-menu"
        @click.stop="setDrawer"
        v-if="$vuetify.breakpoint.name === 'xs'"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-navigation-drawer>
    <!-- header -->
    <v-app-bar app color="secondary" height="60">
      <v-app-bar-nav-icon @click.stop="setDrawer" class="btn-menu">
        <v-icon v-if="!drawer">mdi-menu</v-icon>
        <v-icon v-else>mdi-menu-open</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer v-if="this.$vuetify.breakpoint.name !== 'xs'"></v-spacer>
      <v-toolbar-title class="logo-menu">
        <img
          :src="logoSvg"
          :alt="aluno.sgl_marca"
          :class="aluno.sgl_marca.toLowerCase() + '-logo'"
          v-if="!$vuetify.theme.dark"
        />
        <img
          :src="logoSvgNegativo"
          :alt="aluno.sgl_marca"
          :class="aluno.sgl_marca.toLowerCase() + '-logo'"
          v-else
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <ItensMenu @fechaMenu="drawer = $event" />
    </v-app-bar>
  </div>
</template>
<script>
import ItensMenu from "@/components/ItensMenu.vue";
export default {
  name: "Navbar",
  components: {
    ItensMenu
  },
  data: () => ({
    logoSvg: "",
    logoSvgNegativo: "",
    drawer: false
  }),
  async beforeMount() {
    window.addEventListener("beforeprint", this.impressao); //impedir impressao font grande
  },
  created() {
    if (typeof this.aluno !== "undefined") {
      this.setLogo();
    }
  },
  computed: {
    aluno() {
      return this.$store.state.aluno.aluno;
    }
  },
  methods: {
    setLogo() {
      this.logoSvg = require(`@/assets/svg/Marcas/${this.aluno.sgl_marca.toLowerCase()}.svg`);
      this.logoSvgNegativo = require(`@/assets/svg/Marcas/${this.aluno.sgl_marca.toLowerCase()}-branco.svg`);
    },
    setDrawer() {
      this.drawer = !this.drawer;
      this.$store.dispatch("alterarDrawer");
    },
    impressao() {
      this.drawer = false;
      this.$store.dispatch("fontPadrao");
      this.$vuetify.goTo("#app", {
        duration: 1500,
        offset: 20,
        easing: "easeInOutCubic"
      });
      setTimeout(() => {}, 1000);
    }
  }
};
</script>
