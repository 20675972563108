<template>
  <div>
    <transition name="slide-fade">
      <div class="sticky2 sticky-header primary" id="bar-screen" v-if="aparece">
        <tr>
          <th class="">
            Unidade Curricular
          </th>
          <th class="s-pr-n6">
            Natureza (UC)
          </th>
          <th class="s-pr-n5">
            Carga horária
          </th>
          <th class="s-pr-n7 s-pl-n2">
            Situação
          </th>
          <th class="ch-head">
            Carga horária
          </th>
          <th class="s-pr-n7">
            Unidade Curricular equivalente
          </th>
        </tr>
      </div>
    </transition>
    <v-row class="s-ma-n0 s-mt-n5">
      <v-simple-table id="plano-table" class="mt-4">
        <template v-slot:default>
          <thead id="head-table" class="primary">
            <tr>
              <th class="text-left primary th-1">
                Unidade Curricular (ID)
                <tooltip-question
                  cor="branco"
                  direcao="bottom"
                  texto="Substituindo as disciplinas que fazem parte dos currículos tradicionais, as Unidades Curriculares (UC) são compostas por temas e competências essenciais e necessárias em sua formação para o mundo do trabalho. O (ID) se refere ao código da Unidade Curricular."
                />
              </th>
              <th class="text-leftprimary th-2">
                Natureza (UC)
                <tooltip-question
                  cor="branco"
                  direcao="bottom"
                  texto="Aqui temos o TIPO/MODO de Unidade curricular que você possui em sua Matriz Curricular."
                />
              </th>
              <th class="text-left primary th-3">
                Carga horária
              </th>
              <th class="text-leftprimary th-4">
                Situação
                <tooltip-question
                  cor="branco"
                  direcao="bottom"
                  texto="Aqui temos o status dessa Unidade Curricular após a análise de seu processo."
                />
              </th>
              <th class="text-left divider primary th-5">
                Carga horária
              </th>
              <th class="text-left primary th-6">
                Unidade Curricular equivalente
                <tooltip-question
                  cor="branco"
                  direcao="bottom"
                  texto="Substituindo as disciplinas que fazem parte dos currículos tradicionais, as Unidades Curriculares (UC) são compostas por temas e competências essenciais e necessárias em sua formação para o mundo do trabalho."
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(ciclo, index) in plano">
              <tr class="tr-ciclo" :key="index">
                <td
                  class="pointer"
                  colspan="6"
                  @click="clicando('disc' + index)"
                >
                  <span>
                    <v-icon :id="'disc' + index">
                      mdi-chevron-down
                    </v-icon>
                  </span>
                  {{ ciclo.modulo }}
                </td>
              </tr>
              <tr
                class="tr-linha"
                :class="'disc' + index"
                v-for="disciplina in ciclo.disciplinas"
                :key="disciplina.cod_disciplina"
              >
                <td class="td-plano">
                  <span>{{ disciplina.nome_disciplina }} </span>
                  <span class="num-disciplina"
                    >({{ disciplina.cod_disciplina }})</span
                  >
                </td>
                <td class="td-plano text-center">
                  <span>{{ disciplina.dsc_nat_disciplina }}</span>
                </td>
                <td class="td-plano text-center">
                  <span>{{ disciplina.carga }}</span>
                </td>
                <td class="td-plano">
                  <span>{{ disciplina.situacao }} </span>
                  <v-icon
                    right
                    size="20"
                    class="mb-1 float-right"
                    :color="retornaCorSituacao(disciplina.situacao)"
                  >
                    {{ retornaIconSituacao(disciplina.situacao) }}
                  </v-icon>
                </td>
                <td class="td-plano-b divider-td" colspan="2">
                  <div
                    class="tr-child"
                    v-for="(dispensa, x) in disciplina.dispensas"
                    :key="x"
                  >
                    <div class="td-5">
                      <span>{{ dispensa.carga }}</span>
                    </div>
                    <div class="td-6">
                      <span>{{ dispensa.nome_disciplina }}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </div>
</template>
<script>
import apiAcompanhamento from "@/core/api-access/api-acompanhamento";
import TooltipQuestion from "../shared/TooltipQuestion.vue";
export default {
  name: "PlanoTabelaGradeAntiga",
  components: { TooltipQuestion },
  data() {
    return {
      aparece: false,
      carregado: false,
      plano: {}
    };
  },
  created() {
    window.addEventListener("scroll", this.myFunction);
  },
  destroyed() {
    window.removeEventListener("scroll", this.myFunction);
  },
  computed: {
    drawer() {
      return this.$store.state.util.drawer;
    }
  },
  watch: {
    drawer: {
      handler() {
        if (this.drawer) this.aparece = false;
      }
    }
  },
  beforeMount() {
    this.buscaPlano();
  },
  methods: {
    myFunction() {
      let div = document.getElementById("plano");
      let size = div.getBoundingClientRect();
      if (size.top < -180) {
        if (
          this.$store.state.util.font == 1 &&
          this.drawer == false &&
          window.innerWidth >= 1366
        ) {
          this.aparece = true;
        } else this.aparece = false;
      } else {
        this.aparece = false;
      }
    },
    async buscaPlano() {
      await apiAcompanhamento
        .buscaDisciplinasDispensas("disciplinasDispensas")
        .then(response => {
          if (response.status == 200) {
            //deu certo a busca
            this.plano = response.data.data.ciclos;
            // this.$store.dispatch("alterarAluno", this.aluno);
            this.carregado = true;
          } else {
            //deu errado a busca
            this.msgErro = "Aluno não encontrado.";
          }
        })
        .catch(() => {
          this.msgErro = "Aluno não encontrado.";
        });
    },
    clicando(classe) {
      let componentes = document.getElementsByClassName(classe);
      let btn = document.getElementById(classe);
      if (btn.classList.contains(`mdi-chevron-right`)) {
        btn.classList.remove("mdi-chevron-right");
        btn.classList.add("mdi-chevron-down");
      } else {
        btn.classList.remove("mdi-chevron-down");
        btn.classList.add("mdi-chevron-right");
      }
      componentes.forEach(element => {
        if (element.className == `tr-linha ${classe} visible-false`) {
          element.classList.remove("visible-false");
        } else {
          element.classList.add("visible-false");
        }
      });
    },
    retornaCorSituacao(situacao) {
      switch (situacao) {
        case "Dispensada":
          return "success";
        case "Aprovada":
          return "primary";
        case "Cursando":
          return "purple";
        default:
          return "warning";
      }
    },
    retornaIconSituacao(situacao) {
      switch (situacao) {
        case "Dispensada":
          return "mdi-check-circle";
        case "Aprovada":
          return "mdi-check-circle";
        case "Cursando":
          return "mdi-circle-slice-5";
        default:
          return "mdi-alert-circle";
      }
    }
  }
};
</script>
<style scoped>
.sticky-header {
  position: sticky;
  top: 60px;
  z-index: 9;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.sticky2 {
  height: 57px;
  width: 500%;
  margin-left: -1000px !important;
  padding-left: 1000px !important;
  color: #fff;
  display: flex;
  align-items: center;
}
.sticky2 tr > th:first-child {
  padding-right: 156px !important;
  padding-left: 30px !important;
}
.sticky2 tr .ch-head {
  padding-left: 40px !important;
  padding-right: 48px !important;
}
.visible-false {
  display: none;
}
</style>
