import appConfig from "../config/app";
import Api from "./api";
class ApiAcompanhamento extends Api {
  constructor(host) {
    super(host);
  }
  buscaAluno() {
    return this.postCandidato(`acompanhamento/dadosCandidato/`, {});
  }
  buscaDisciplinasDispensas(url) {
    return this.postCandidato(`acompanhamento/${url}/`, {});
  }
  buscaTextoDeferimento(body) {
    return this.postCandidato(`acompanhamento/textodeferimento/`, body);
  }
  buscaDadosIntegralizacao() {
    return this.postCandidato(`acompanhamento/dadosIntegracao/`, {});
  }
  aceiteTermoPrev() {
    return this.put(`acompanhamento/aceiteTermo/`);
  }
}

const apiAcompanhamento = new ApiAcompanhamento(appConfig.api.api_ingressa_coi);
export default apiAcompanhamento;
