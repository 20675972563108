import axios from "axios";
//import userManager from "@/core/user-manager";
import _ from "lodash";
import Errors from "@/core/api-access/error/Errors";
import store from "@/store";

export default class Api {
  constructor(host) {
    this._host = host;
    this._errors = new Errors();
    this._submmited = false;
  }
  _getAccessToken() {
    if (store.state.util.tokenIdentity !== null) {
      return Promise.resolve(store.state.util.tokenIdentity);
    } else {
      const config = {
        method: "get",
        url: process.env.VUE_APP_URL_INGRESSAR_COI+'token/interno',
        headers: {}
      };
      
      return new Promise((resolve, reject) => {
        axios(config)
          .then(response => {
            store.dispatch("alterarTokenIdentity", response.data.data.access_token);
            resolve(response.data.data.access_token);
          })
          .catch(error => {
            store.dispatch("alterarTokenIdentity", null);
            reject(error);
          });
      });
    }
  }
  _setHeaderAuthorization(options, accessToken) {
    let opt = options || {};
    if (!opt.headers) {
      opt.headers = {
        Authorization: `Bearer ${accessToken}`
      };
    } else {
      opt.headers.Authorization = `Bearer ${accessToken}`;
    }
    return opt;
  }

  submit(requestType, url, options = null, body = null) {
    this._submmited = true;
    this._errors.clear();
    return new Promise((resolve, reject) => {
      this._getAccessToken().then(accessToken => {
        options = this._setHeaderAuthorization(options, accessToken);
        axios({
          method: requestType,
          url: `${this._host}${url}`,
          data: body,
          headers: options.headers
        })
          .then(response => {
            this._submmited = false;
            resolve(response);
          })
          .catch(error => {
            this._submmited = false;
            //this._errors.record(error);
            reject(error);
          });
      });
    });
  }

  submitSemHeader(requestType, url, body = null) {
    this._submmited = true;
    this._errors.clear();
    return new Promise((resolve, reject) => {
      axios({
        method: requestType,
        url: `${this._host}${url}`,
        data: body
      })
        .then(response => {
          this._submmited = false;
          resolve(response);
        })
        .catch(error => {
          this._submmited = false;
          this._errors.record(error);
          reject(error);
        });
    });
  }

  get(url, options, filtros = null, eagerLoad = []) {
    let queryString =
      this.preparaFiltros(filtros) !== ""
        ? `?${this.preparaFiltros(filtros)}`
        : "";
    if (queryString === "") {
      queryString =
        this.preparaEagerLoad(eagerLoad) !== ""
          ? `?${this.preparaEagerLoad(eagerLoad)}`
          : queryString;
    } else {
      queryString =
        this.preparaEagerLoad(eagerLoad) !== ""
          ? `${queryString}&${this.preparaEagerLoad(eagerLoad)}`
          : queryString;
    }
    return this.submit("get", url + queryString, options);
  }
  getSemHeader(url, filtros = null, eagerLoad = []) {
    let queryString =
      this.preparaFiltros(filtros) !== ""
        ? `?${this.preparaFiltros(filtros)}`
        : "";
    if (queryString === "") {
      queryString =
        this.preparaEagerLoad(eagerLoad) !== ""
          ? `?${this.preparaEagerLoad(eagerLoad)}`
          : queryString;
    } else {
      queryString =
        this.preparaEagerLoad(eagerLoad) !== ""
          ? `${queryString}&${this.preparaEagerLoad(eagerLoad)}`
          : queryString;
    }
    return this.submitSemHeader("get", url + queryString);
  }
  head(url, options) {
    return this.submit("head", url, options);
  }
  delete(url, options) {
    return this.submit("delete", url, options);
  }
  post(url, body, options) {
    return this.submit("post", url, options, body);
  }
  postCandidato(url, body, options) {
    const candidato = store.state.util.token;
    body = { ...body, candidato };
    return this.submit("post", url, options, body);
  }
  postSemHeader(url, body) {
    return this.submit("post", url, body);
  }
  put(url, body, options) {
    return this.submit("put", url, options, body);
  }
  patch(url, body, options) {
    return this.submit("get", url, options, body);
  }
  preparaFiltros(params) {
    if (typeof params === "string") {
      return params;
    }
    let queryString = "";
    _.forEach(params, (value, key) => {
      if (value !== "") {
        queryString = queryString + key + "=" + value + "&";
      }
    });
    queryString = queryString.substr(0, queryString.length - 1);
    return queryString;
  }
  preparaEagerLoad(params) {
    let queryString = "";
    params.forEach((value, index) => {
      if (index === 0) {
        queryString = "with=";
      }
      queryString = queryString + value + ",";
    });
    queryString = queryString.substr(0, queryString.length - 1);
    return queryString;
  }
}
