<template>
  <div>
    <v-row class="s-pa-n6 s-pb-n5 pt-0 ma-0" id="myHeader">
      <v-col cols="12">
        <h2>Resultado da análise</h2>
      </v-col>
    </v-row>
    <v-row class="s-pa-n0 pt-0  ma-0 disabled">
      <div class="hidden-sm-and-down">
        <NavFixed />
      </div>
      <div class="hidden-md-and-up">
        <NavFooter />
      </div>
    </v-row>
    <div class="content">
      <div id="resumo" class="ma-0 s-pa-n6 pt-0 s-pb-n0">
        <ResumoAnalise />
      </div>
      <div id="plano">
        <div
          class="hidden-sm-and-down ma-0 s-pa-n6 s-pt-n4"
          id="plano-container"
        >
          <PlanoContainer />
        </div>
        <div class="hidden-md-and-up ma-0 s-pa-n4 s-pt-n4">
          <PlanoMobile />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlanoContainer from "@/components/PlanoCurricular/PlanoContainer.vue";
import NavFooter from "@/components/PlanoCurricular/NavFooter.vue";
import NavFixed from "@/components/PlanoCurricular/NavFixed.vue";
import PlanoMobile from "@/components/PlanoCurricular/PlanoMobile.vue";
import ResumoAnalise from "@/components/PlanoCurricular/ResumoAnalise.vue";
export default {
  name: "ResultadoAnalise",
  components: {
    PlanoContainer,
    PlanoMobile,
    ResumoAnalise,
    NavFooter,
    NavFixed
  },
  data() {
    return {
      tab: null,
      items: ["Resumo", "Plano Curricular"]
      //fab: true
    };
  },
  methods: {
    goTransition(id) {
      this.$vuetify.goTo(id, {
        duration: 1500,
        offset: 20,
        easing: "easeInOutCubic"
      });
      this.fab = true;
    }
  }
};
</script>
