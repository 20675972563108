<template>
  <v-col
    cols="12"
    md="7"
    sm="12"
    :class="$vuetify.breakpoint.width < 600 ? 's-mb-n4' : ''"
  >
    <v-card
      class="s-pa-n3 mr-md-4 mb-4 mb-md-0 mb-sm-4 mb-lg-0 pa-md-5"
      height="100%"
    >
      <v-card-title class="titulo-card ma-0 pa-0 ">
        <v-icon large left color="primary">mdi-account-circle</v-icon>
        <h5 class="title-break">{{ aluno.nom_aluno }}</h5>
      </v-card-title>

      <v-card-actions
        class="info-candidato-1 s-mt-n4 s-mb-n4 pa-0"
        :class="fontSize > 1.55 ? 'info-candidato-grande' : ''"
      >
        <v-row>
          <v-col cols="12" md="5" class="order-md-first">
            <p>Seus dados:</p>
            <p><b>CPF:</b> {{ aluno.num_cpf }}</p>
            <p><b>Matrícula:</b> {{ aluno.num_matricula }}</p>
            <p><b>Matriz Curricular:</b> {{ aluno.grade }}</p>
            <p><b>Campanha:</b> {{ aluno.periodo }}</p>
            <p><b>Forma de Ingresso:</b> {{ aluno.forma_entrada }}</p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            xd="12"
            class="imagem-card-direita d-flex align-end flex-column d-print-none"
          >
            <!-- <v-img :src="illustration" class="mr-5" max-width="100%"></v-img> -->
            <img
              :src="illustration"
              v-if="fontSize < 1.25 && $vuetify.breakpoint.width > 320"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
export default {
  name: "DadosAluno",
  data: () => ({
    illustration: require(`@/assets/svg/Ilustracoes/illustration-woman.png`)
  }),
  computed: {
    aluno() {
      return this.$store.state.aluno.aluno;
    },
    fontSize() {
      return this.$store.state.util.font;
    }
  }
};
</script>
