<template>
  <div>
    <div class="home" v-if="carregado">
      <NavBar />
      <v-container class="fill-height pa-0 container-limited" fluid>
        <v-row
          id="infos-aluno"
          :class="
            $vuetify.breakpoint.name !== 'xs'
              ? 'ma-0 s-pa-n6 s-pb-n5'
              : 'ma-0 s-pa-n4 s-pt-n4'
          "
        >
          <DadosAluno />
          <ResumoAluno />
        </v-row>
        <ResultadoAnalise />
      </v-container>
    </div>
    <div v-else-if="erro">
      <NotFound />
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import DadosAluno from "@/components/PlanoCurricular/DadosAluno.vue";
import ResumoAluno from "@/components/PlanoCurricular/ResumoAluno.vue";
import ResultadoAnalise from "@/components/PlanoCurricular/ResultadoAnalise.vue";
import NotFound from "@/views/NotFound.vue";
import Loading from "@/views/Loading.vue";

import urlLinks from "@/core/util/urlAcompanhamento.js";
import apiAcompanhamento from "@/core/api-access/api-acompanhamento";
//import DesignSystem from "@/components/DesignSystem.vue";
export default {
  name: "PlanoCurricular",
  components: {
    NavBar,
    DadosAluno,
    ResumoAluno,
    NotFound,
    Loading,
    //DesignSystem,
    ResultadoAnalise
  },
  data: () => ({
    aluno: {},
    carregado: false,
    erro: false,
    link: ""
  }),
  async beforeMount() {
    this.$store.dispatch("alterarIe", this.$route.params.ie);
    this.$store.dispatch("alterarToken", this.$route.params.cpf);
    let dataLayer = [
      {
        Marca: this.$route.params.ie
      }
    ];
    this.$gtm.options.dataLayer = dataLayer;
    this.$gtm.push({ Marca: this.$route.params.ie });

    await apiAcompanhamento
      .buscaAluno()
      .then(response => {
        if (response.status == 200) {
          //deu certo a busca
          this.aluno = response.data.data.candidato;
          this.$store.dispatch("alterarAluno", this.aluno);
          this.carregado = true;
        } else {
          //deu errado a busca
          this.erro = "true";
        }
      })
      .catch(() => {
        this.erro = "true";
      })
      .finally(() => {
        this.setLink();
      });
  },
  methods: {
    setLink() {
      this.link = urlLinks[this.aluno.sgl_marca];
      this.link = this.link.replace("<concurso>", this.aluno.codconc);
      this.link = this.link.replace("<cpf>", this.aluno.num_cpf);
      this.$store.dispatch("alterarLink", this.link);
    }
  }
};
</script>
